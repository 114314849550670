import React from "react";
import { ContactFormTitle } from "@/data";
import { useForm, isRequired, isValidEmail } from '@/components/useForm';


const ContactForm = () => {
  const { subTitle, title, description } = ContactFormTitle;

  const initialState = { fullName: '', email: '', message: '' };
  const validations = [
    ({ email }) => isValidEmail(email) || { email: 'E-mail is required' },
    ({ fullName }) => isRequired(fullName) || { fullName: 'Name is required' },
    ({ message }) => isRequired(message) || { message: 'Message is required' },
  ];
  const { values, isValid, errors, changeHandler, touched, submitHandler, submitted } = useForm(initialState, validations);

  return (
    <section className="commonSection contact-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h4 className="sub_title">{subTitle}</h4>
            <h1 className="sec_title">{title}</h1>
            <p className="sec_desc">{description}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 offset-lg-2 col-sm-12 col-md-10 offset-md-1">
            <form
              method="post"
              className="contact-form"
              id="contactForm"
              onSubmit={submitHandler}
            >
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <label htmlFor="name">*Full Name</label>
                  <input
                    className="input-form required"
                    type="text"
                    name="fullName"
                    id="fullName"
                    placeholder="Full Name"
                    required
                    value={values.fullName}
                    onChange={changeHandler}
                  />
                  {touched.name && errors.name && <p className="error" style={{ float: "left" }}>{errors.name}</p>}
                </div>
                <div className="col-lg-12 col-sm-12">
                  <label htmlFor="email">*Email Address</label>
                  <input
                    className="input-form required"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    required
                    value={values.email}
                    onChange={changeHandler}
                  />
                  {touched.email && errors.email && <p className="error" style={{ float: "left" }}>{errors.email}</p>}
                </div>
                <div className="col-lg-12 col-sm-12">
                  <label htmlFor="phone">(Optional) Phone Number:</label>
                  <input
                    className="input-form"
                    type="tel"
                    name="phone"
                    id="phone"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    placeholder="ex. 555-555-5555"
                  />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <label htmlFor="message">*Message</label>
                  <textarea
                    className="input-form required"
                    name="message"
                    id="message"
                    required
                    placeholder="Write Message"
                    value={values.message}
                    onChange={changeHandler}
                  ></textarea>
                  {touched.message && errors.message && <p className="error" style={{ textAlign: "left" }}>{errors.message}</p>}

                  <label>* indicates required field</label>
                  <input type="text" name="_gotcha" style={{ display: 'none' }} />
                  <input type="hidden" name="_next" value="https://www.madaytalentpartners.com/contact" />
                </div>
                {submitted.isSubmitted && submitted.isError && <p className="error" style={{ textAlign: "center" }}>Error submitting form.  Please try again</p>}
                {submitted.isSubmitted && !submitted.isError && <p style={{ textAlign: "center", color: "green" }}>Thank you for contacting us.  We will be reaching out to you shortly.</p>}
              </div>
              <button
                className="common_btn red_bg"
                type="submit"
                disabled={!isValid}
              >
                <span>Send Message</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;