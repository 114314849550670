import React from "react";

import SEO from "../components/seo";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import ContactForm from "@/components/contact-form";
import PageBanner from "@/components/page-banner";
import MenuContextProvider from "@/context/menu-context";
import HeaderTwo from "@/components/header-two";

const ContactPage = ({ data }) => {
  const title = `Contact Us`;
  return (
    <MenuContextProvider>
        <Layout PageTitle={title}>
          <SEO title={title} />
          <HeaderTwo />
          <PageBanner title="Contact Us" />
          <ContactForm />
          <Footer />
        </Layout>
    </MenuContextProvider>
  );
};

export default ContactPage;
