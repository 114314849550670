import { useState } from 'react';

function validate(validations, values) {
    const errors = validations
        .map(validation => validation(values))
        .filter(validation => typeof validation === 'object');
    return { isValid: errors.length === 0, errors: errors.reduce((errors, error) => ({ ...errors, ...error }), {}) };
}

export function useForm(initialState = {}, validations = []) {
    const { isValid: initialIsValid, errors: initialErrors } = validate(validations, initialState);
    const [touched, setTouched] = useState({});
    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState(initialErrors);
    const [isValid, setValid] = useState(initialIsValid);
    const [submitted, setSubmitted] = useState({ isSubmitted: false, isError: false });

    const changeHandler = ({ target: { value, name } }) => {
        const newValues = { ...values, [name]: value };
        const { isValid, errors } = validate(validations, newValues);
        setValues(newValues);
        setErrors(errors);
        setValid(isValid);
        setTouched({ ...touched, [name]: true });
    };

    const resetForm = () => {
        setValues(initialState);
    }

    const submitHandler = (e) => {
        e.preventDefault();
        const options = {
            method: 'Post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        };
        fetch("https://formspree.io/f/mqkndnob", { ...options })
            .then(r => r.json())
            .then(response => {
                if (response.success === 'email sent' || response.ok) {
                    console.log("Success: Form Submitted");
                    resetForm();
                    setSubmitted({ isSubmitted: true, isError: false });
                    setTimeout(() => {
                        setSubmitted({ isSubmitted: false, isError: false });
                    }, 4000);
                } else {
                    console.log("Error: Form Submitted");
                    setSubmitted({ isSubmitted: true, isError: true });
                }
            })
            .catch(error => {
                console.log("Catch Error: Form Submitted");
                setSubmitted({ isSubmitted: true, isError: true });
            });
    };

    return { values, errors, touched, isValid, changeHandler, submitHandler, submitted };
}

export function isRequired(value) {
    return value != null && value.trim().length > 0;
}

export function isValidEmail(value) {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!value || regex.test(value) === false) {
        return false;
    }
    return true;
}